import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Col, Row, Card } from 'react-bootstrap';
import { walletImgRender } from 'services/coins/components';
import { Link } from 'react-router-dom';
import Flex from 'components/common/Flex';
import { PortfolioRow as textLang } from 'staticData/languages';
import BacktestChart from '../Portfolio/Backtesting/BacktestChart';
import AppContext from 'context/Context';
import SoftBadge from 'components/common/SoftBadge';
import LottieBotLazy from 'services/lazyLoading/LottieBotLazy';
import { breakpoints } from 'helpers/utils';

const PortfolioCol = ({ portfolio, chartMin, chartMax }) => {
  const {
    config: { lang, currency }
  } = useContext(AppContext);
  const { id, name, coins, backtestData, bgStyle, topPerformer } = portfolio;

  const momMax = Math.max(...backtestData.map(obj => obj.AVG_MONTHLY_RETURN));

  const thisYear = new Date().getFullYear();
  const backtestCleanData = Object.fromEntries(
    Object.entries(
      backtestData[backtestData.length - 1].CHARTS[thisYear]
    ).filter(([key]) => key !== 'USDT')
  );

  // Get new url with the new id
  // Create a new URL object from the current window location
  const url = new URL(window.location.href);
  // Create a URLSearchParams object to handle the query parameters
  const params = new URLSearchParams(url.search);
  params.set('type', 'portfolio');
  // Add or update the 'id' parameter
  params.set('id', id);
  // Update the search property of the URL object
  url.search = params.toString();

  return (
    <Card
      as={Link}
      to={url.search}
      style={{
        textDecoration: 'none',
        color: 'inherit',
        backgroundImage: bgStyle
      }}
      className="pointerCursor"
    >
      <Card.Body className="pt-3 pb-0">
        <Row style={{ minHeight: '10rem' }}>
          <Col xs={5} as={Flex} className="px-0">
            <LottieBotLazy type={id} loop={true} style={{ width: '100%' }} />
          </Col>
          <Col xs={7} className="ps-0">
            <Row className="mt-4">
              <small>{'AI Portfolio'}</small>
              <h5 className="mb-0">{name}</h5>
              {walletImgRender(coins, 'mt-2', true, 'm')}
              {id === '8' && (
                <>
                  <SoftBadge
                    pill
                    className="fs--2 ms-3 mt-3"
                    style={{ width: 'auto' }}
                    bg="primary"
                  >
                    Investor
                  </SoftBadge>
                  <SoftBadge
                    pill
                    className="fs--2 ms-1 mt-3"
                    style={{ width: 'auto' }}
                    bg="success"
                  >
                    Private
                  </SoftBadge>
                </>
              )}
              {topPerformer && (
                <div>
                  <SoftBadge
                    pill
                    className="fs--2 mt-2 d-sm-none"
                    style={{ width: 'auto' }}
                    bg="info"
                  >
                    {textLang.bestPerformerMobileMonth[lang]}
                  </SoftBadge>
                </div>
              )}
            </Row>
          </Col>
        </Row>
        <Row>
          <Col>
            <BacktestChart
              data={backtestCleanData}
              currency={currency}
              simplify={true}
              min={chartMin}
              max={chartMax}
              style={{
                height:
                  window.innerWidth >= breakpoints['xxl'] ||
                  window.innerWidth < breakpoints['sm']
                    ? '150px'
                    : '80px'
              }}
            />
          </Col>
        </Row>
        <Row className="mt-4 mb-2">
          <Col xs={6} className="text-center text-dark px-sm-0 px-xxl-3">
            <p className="mt-2 mb-0">{textLang.avgReturn[lang]} (ALL)</p>
            <p className="mt-2 fs-3">
              <b>+{Math.abs(Number(momMax).toFixed(2))}%</b>
            </p>
          </Col>
          <Col xs={6} className="text-center text-dark px-sm-0 px-xxl-3">
            <p className="mt-2 mb-0">
              {textLang.return[lang]} <br /> ({thisYear})
            </p>
            <p className="mt-2 fs-3">
              <b>
                +
                {Math.abs(
                  Number(
                    backtestData[backtestData.length - 1].RETURN_PER_YEAR[
                      thisYear
                    ]
                  ).toFixed(2)
                )}
                %
              </b>
            </p>
          </Col>
        </Row>
      </Card.Body>
      {/* Best performer */}
      {topPerformer && (
        <div
          style={{ position: 'absolute', top: '10px', right: '10px' }}
          className="d-none d-sm-block"
        >
          <SoftBadge pill bg={'info'}>
            {textLang.bestPerformerMonth[lang]}
          </SoftBadge>
        </div>
      )}
    </Card>
  );
};

PortfolioCol.propTypes = {
  portfolio: PropTypes.object,
  chartMin: PropTypes.number,
  chartMax: PropTypes.number
};

export default PortfolioCol;
