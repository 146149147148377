import React, { useContext, useState, useEffect, lazy, Suspense } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import Done from 'pages/CreateBot/Done';
import { Row, Col, Spinner } from 'react-bootstrap';
import AppContext from 'context/Context';
import { endpointInterfaceV2 } from 'services/endpointInterface/endpointInterfaceV2';
import { cleanCache } from 'services/cache';
import { calculateCoinsValue } from 'services/coins/functions';
import { firstProTier, secondProTier } from 'staticData/common';
import { removeFromLocalStorage } from 'staticData/common';
import Flex from 'components/common/Flex';
import CreateBotCover from './CreateBotCover';
import { trackEvent } from 'services/externals/mixpanel';

const CreateBot = () => {
  const {
    config: { savedBotParams },
    userInfos,
    coinValues
  } = useContext(AppContext);
  // can get id from query parameter
  const queryParameters = new URLSearchParams(window.location.search);
  const createType = queryParameters.get('type');
  const urlBotID = queryParameters.get('id');
  const walletUrl = queryParameters.get('wallet');
  const subscription = queryParameters.get('subscription');
  const query_sub_type = queryParameters.get('sub_type');
  const query_trial_days = queryParameters.get('trial_days');
  const query_tier = queryParameters.get('tier');
  const query_months = queryParameters.get('months');
  const subMap = {
    1: 'Explorer',
    2: 'Investor'
  };
  const [createdBot, setCreatedBot] = useState();
  const routerHistory = useHistory();
  const componentCache = {};

  const maxFirstProTierTotalAllocation = userInfos.permissions?.max_real_capital
    ? userInfos.permissions.max_real_capital
    : 10000;

  const createBot = async () => {
    const filteredParams = { ...savedBotParams };
    // del useless keys
    delete filteredParams.time;
    delete filteredParams.botCategory;
    let resp = await endpointInterfaceV2({
      internalEndpoint: 'createBot',
      httpMethod: 'post',
      usingCredentials: true,
      bodyParams: filteredParams
    });
    if (resp.validResponse) {
      if (resp.data.bot_task) setCreatedBot({ bot_task: resp.data.bot_task });
      else setCreatedBot(resp.data.bot_info);
      await cleanCache(0);
      removeFromLocalStorage('savedBotParams');
    } else routerHistory.push('/invest');
  };

  const handleCreateBot = coins => {
    // check if there is a bot to start
    if (
      // check if is REAL bot
      (savedBotParams &&
        Object.keys(savedBotParams).length &&
        !savedBotParams?.virtual &&
        (userInfos.pro_tier >= secondProTier ||
          (userInfos.pro_tier === firstProTier &&
            calculateCoinsValue(savedBotParams.wallet || { USDT: 0 }, coins) <
              maxFirstProTierTotalAllocation))) ||
      // check if is VIRTUAL bot
      (savedBotParams &&
        savedBotParams?.virtual &&
        userInfos.pro_tier >= firstProTier)
    ) {
      // track event
      if (query_sub_type === 'upgrade')
        trackEvent('upgrade', {
          trial: true,
          months: query_months || userInfos?.subscription?.recurring_months
        });
      else
        trackEvent('subscription', {
          trial_days: query_trial_days,
          subscription_type: subMap[String(query_tier)] || null,
          months: query_months
        });
      // Remove URL parameters after tracking
      const newQueryParams = new URLSearchParams(window.location.search);
      newQueryParams.delete('sub_type');
      newQueryParams.delete('trial_days');
      newQueryParams.delete('tier');
      newQueryParams.delete('months');
      queryParameters.set('subscription', 'accepted');
      routerHistory.push(`?${queryParameters.toString()}`);

      routerHistory.replace({
        pathname: window.location.pathname,
        search: newQueryParams.toString()
      });

      // Create a Date object from the specified string
      const specificDate = new Date(savedBotParams.time);
      // Add one hour (3600000 milliseconds) to the specified date
      const specificDatePlusOneHour = new Date(
        specificDate.getTime() + 3600000
      );
      // Get the current date and time
      const now = new Date();
      // Compare the current date with the specified date plus one hour
      if (now <= specificDatePlusOneHour) createBot();
    } else routerHistory.push('/invest');
  };

  const hasAgentPermissions = () => {
    if (userInfos.permissions.bot_type_real_allowed_categories.includes('bot'))
      return true;
    return false;
  };

  // start LAZY LOADING with preloading
  const preloadComponent = componentImport => {
    if (!componentCache[componentImport]) {
      componentCache[componentImport] = componentImport();
    }
  };

  const lazyWithPreload = componentImport => {
    const LazyComponent = lazy(componentImport);
    LazyComponent.preload = () => preloadComponent(componentImport);
    return LazyComponent;
  };

  // lazy import
  // AGENTS
  const SelectBot = lazyWithPreload(() =>
    import('./Agent/SelectBot/SelectBot')
  );
  const Wallet = lazyWithPreload(() => import('./Agent/Wallet'));
  // PORTFOLIO
  const SelectPortfolio = lazyWithPreload(() =>
    import('./Portfolio/SelectPortfolio/SelectPortfolio')
  );
  const CreatePortfolioDetails = lazyWithPreload(() =>
    import('./Portfolio/Backtesting/CreatePortfolioDetails')
  );
  const PortfolioWallet = lazyWithPreload(() =>
    import('./Portfolio/PortfolioWallet')
  );

  useEffect(() => {
    // Prefetch components
    // Agents
    SelectBot.preload();
    Wallet.preload();
    // Portfolio
    SelectPortfolio.preload();
    CreatePortfolioDetails.preload();
    PortfolioWallet.preload();
  }, []);
  // end LAZY LOADING with preloading

  useEffect(() => {
    if (
      subscription === 'success' &&
      coinValues?.current &&
      Object.keys(coinValues?.current).length
    )
      handleCreateBot(coinValues.current);
    return () => {
      if (subscription) setCreatedBot();
    };
  }, [coinValues.current]);

  useEffect(() => {
    // show only portfolio for all user without bot permissions
    if (subscription !== 'success' && !hasAgentPermissions()) {
      queryParameters.set('type', 'portfolio');
      routerHistory.push(`?${queryParameters.toString()}`);
    }
  }, [window.location.search]);

  return (
    <>
      {subscription ? (
        createdBot ? (
          <Done
            createdBot={createdBot}
            setCreatedBot={setCreatedBot}
            isPortfolio={savedBotParams.botCategory === 'portfolio'}
          />
        ) : (
          <Row>
            <Col
              as={Flex}
              className="justify-content-center align-items-center py-5"
            >
              <Spinner />
            </Col>
          </Row>
        )
      ) : !createType ? (
        <CreateBotCover />
      ) : createType === 'bot' ? (
        <>
          {!urlBotID ? (
            <Suspense
              fallback={
                <Flex className="justify-content-center my-5">
                  <Spinner animation="border" />
                </Flex>
              }
            >
              <SelectBot />
            </Suspense>
          ) : !createdBot ? (
            <Suspense fallback={<Spinner animation="border" />}>
              <Wallet id={urlBotID} setCreatedBot={setCreatedBot} />
            </Suspense>
          ) : (
            <Done createdBot={createdBot} setCreatedBot={setCreatedBot} />
          )}
        </>
      ) : createType === 'portfolio' ? (
        <>
          {!urlBotID ? (
            <Suspense
              fallback={
                <Flex className="justify-content-center my-5">
                  <Spinner animation="border" />
                </Flex>
              }
            >
              <SelectPortfolio />
            </Suspense>
          ) : !createdBot ? (
            walletUrl !== 'show' ? (
              <Suspense
                fallback={
                  <Flex className="justify-content-center my-5">
                    <Spinner animation="border" />
                  </Flex>
                }
              >
                <CreatePortfolioDetails id={urlBotID} />
              </Suspense>
            ) : (
              <Suspense
                fallback={
                  <Flex className="justify-content-center my-5">
                    <Spinner animation="border" />
                  </Flex>
                }
              >
                <PortfolioWallet
                  id={urlBotID}
                  setCreatedBot={setCreatedBot}
                  maxFirstProTierTotalAllocation={
                    maxFirstProTierTotalAllocation
                  }
                />
              </Suspense>
            )
          ) : (
            <Done
              createdBot={createdBot}
              setCreatedBot={setCreatedBot}
              isPortfolio={true}
            />
          )}
        </>
      ) : (
        <Redirect to="/" />
      )}
    </>
  );
};

export default CreateBot;
